export let Project = {
  address: '86-90 Paul Street\nLondon, EC2A 4NE',
  api: 'https://staging-api.hoxtonmix.com/api/v3/',
  appStore: 'https://apps.apple.com/gb/app/hoxton-mix/id6449089524',
  auth: {
    cognito: true,
  },

  //https://checkout.hoxtonmix.com
  chargbeeSite: 'hoxtonmix-test',

  chargebeeUrl: 'https://hoxtonmix-test.chargebee.com',

  codepush: {
    android: {
      production: 'ZgrOQgAz1Xtx1BdhcNCk5moU9_etIfPr8Ale8',
      staging: '_Y4F2rRWKOUIpaSXryXt5DwdoAzZwfQnbICNu',
    },
    ios: {
      production: '_eKRhxI_Zb6lizWQQwbCsGkdtJsbqr5M-Cn2P',
      staging: 'BpAa6k1Z_H8ABhUiCGBkLiZf9DRij5te80pSX',
    },
  },
  cognito: {
    region: 'eu-west',
    userPoolId: 'eu-west-2_keD3zW0Dd',
    userPoolWebClientId: 't9sgma6dtere3s4rd18hep7u3',
  },
  cognitoMobile: {
    region: 'eu-west',
    userPoolId: 'eu-west-2_keD3zW0Dd',
    userPoolWebClientId: 't9sgma6dtere3s4rd18hep7u3',
  },
  companyAPI: 'https://staging-api.hoxtonmix.com/api/v3/',
  crisp: '708c694e-aa84-49e2-8cd3-0fb15d186d01',
  flagsmith: 'EbTffg24SufF6SoG4hZmjm',
  gtm: 'GTM-K636ZW',
  hoxtonmixUrl: 'https://staging-www.hoxtonmix.com/',
  logs: {
    ANALYTICS: true,
    API: false,
    STORAGE: false,
  },
  loqate: 'PR28-BF96-PB24-GY46',
  locale: 'en-gb',
  mobileApp: 'hmportal',
  paypal:
    'ARLU1HFkZQXD_swGtgbR2tN7f-bFYoyYlPUL69PmwHFOioelEiSh-NxichfQuPCj05UoNAeUE0Ptz-xB',
  playStore:
    'https://play.google.com/store/apps/details?id=com.hoxtonmix.portal',
  portalUrl: 'https://hoxtonmix-portal-dev.vercel.app',
  redirectSignIn: '/auth/account-verify/',
  sentry:
    'https://7c4558361cd248d3bcdc4469f4861b32@o31090.ingest.sentry.io/4505487325265920',
  sentryEnvironment: 'development',
  siteName: 'Hoxton Mix',
  stripe: 'pk_test_CvegxsaywY0KQ1iu62m08PQx',
  testCafeStage: 'staging',
  ...(typeof projectOverrides !== 'undefined' ? projectOverrides : {}),
}

export function setProject(newProject) {
  Project = newProject
}
